
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  tags: string[]
}

export default defineComponent({
  name: 'AggregateTab',
  props: {
    tags: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props: Props) {
    const { t } = useI18n()

    return { t }
  },
})
