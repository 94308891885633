import { http } from '@/plugins/ky'
import { ADMIN } from '@/constants'
import { join } from 'path'
import { ApiCustomerService } from '@/types/api'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'

const get = () =>
  new Promise<ApiCustomerService[]>((resolve, reject) =>
    http
      .get(join(ADMIN, 'customers', 'services'))
      .json<ApiCustomerService[]>()
      .then((res) => resolve(res))
      .catch(({ response, message }) => {
        const { dispatch } = useStore()

        dispatch(ActionTypes.SetNotice, {
          message,
        })
        reject()
      })
  )

export { get }
