<template>
  <thead class="bg-gray-50">
    <tr>
      <th
        v-for="(tag, index) in tags"
        :key="index"
        scope="col"
        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {{ t(tag) }}
      </th>
    </tr>
  </thead>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  tags: string[]
}

export default defineComponent({
  name: 'AggregateTab',
  props: {
    tags: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props: Props) {
    const { t } = useI18n()

    return { t }
  },
})
</script>
