<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-2">
      <div class="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-2">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <slot name="header"></slot>
            <slot name="body"></slot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
