import { http } from '@/plugins/ky'
import { ADMIN } from '@/constants'
import { join } from 'path'
import { ApiUserResult } from '@/types/api'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'

const get = (customerId: string) =>
  new Promise<ApiUserResult[]>((resolve, reject) =>
    http
      .get(join(ADMIN, 'customer', 'users'), {
        searchParams: {
          customerId,
        },
      })
      .json<ApiUserResult[]>()
      .then((res) => resolve(res))
      .catch(({ response, message }) => {
        const { dispatch } = useStore()

        dispatch(ActionTypes.SetNotice, {
          message,
        })
        reject()
      })
  )

export { get }
