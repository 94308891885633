
import { defineComponent } from 'vue'
import Thead from '@/components/Parts/Table/Thead.vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  name: 'CustomerServicesTab',
  components: { Thead },
  setup() {
    const { t } = useTranslation()
    const tags = [
      'Organization',
      'Service name',
      'Plan',
      'Status',
      'Started at',
      'Ended at',
      'Contract end date',
      'Payment date',
      'Action',
    ]
    return { t, tags }
  },
})
