<template>
  <div
    v-if="dialog"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                id="modal-title"
                class="text-lg leading-6 font-medium text-gray-900 border-b-2"
              >
                {{ t('User') }}
              </h3>
              <div
                v-for="(user, index) in users"
                :key="index"
                class="border-b-2 text-xs py-2"
              >
                <p>{{ user.name }}</p>
                <p>{{ user.email }}</p>
                <p>{{ user.division }}</p>
                <p>{{ user.title }}</p>
                <p>{{ user.phone }}</p>
              </div>
              <p v-if="users.length === 0">{{ t('Now loading ..') }}</p>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="onClose"
          >
            {{ t('Close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, watch, toRefs } from 'vue'
import Thead from '@/components/Parts/Table/Thead.vue'
import { useTranslation } from '@/locales'
import { useArrayRefs } from '@/reactives'
import { get as getCustomerUsers } from '@/api/admin/customer/users'
import { ApiCustomerService, ApiUserResult } from '@/types/api'

type Props = {
  dialog: boolean
  customer?: ApiCustomerService
}

export default defineComponent({
  name: 'UserDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object as PropType<ApiCustomerService | undefined>,
      default: undefined,
      required: false,
    },
  },
  emits: ['close'],
  setup(props: Props, { emit }) {
    const { dialog, customer } = toRefs(props)
    const { t } = useTranslation()
    const users = useArrayRefs<ApiUserResult>()

    watch(dialog, () => {
      if (dialog.value && customer != null && customer.value != null) {
        getCustomerUsers(customer.value.id).then((result) => {
          users.value = result
        })
      }
    })

    const onClose = () => {
      emit('close')
      users.value = []
    }

    return { t, users, onClose }
  },
})
</script>
