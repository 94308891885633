
import { defineComponent, PropType, watch, toRefs } from 'vue'
import Thead from '@/components/Parts/Table/Thead.vue'
import { useTranslation } from '@/locales'
import { useArrayRefs } from '@/reactives'
import { get as getCustomerUsers } from '@/api/admin/customer/users'
import { ApiCustomerService, ApiUserResult } from '@/types/api'

type Props = {
  dialog: boolean
  customer?: ApiCustomerService
}

export default defineComponent({
  name: 'UserDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object as PropType<ApiCustomerService | undefined>,
      default: undefined,
      required: false,
    },
  },
  emits: ['close'],
  setup(props: Props, { emit }) {
    const { dialog, customer } = toRefs(props)
    const { t } = useTranslation()
    const users = useArrayRefs<ApiUserResult>()

    watch(dialog, () => {
      if (dialog.value && customer != null && customer.value != null) {
        getCustomerUsers(customer.value.id).then((result) => {
          users.value = result
        })
      }
    })

    const onClose = () => {
      emit('close')
      users.value = []
    }

    return { t, users, onClose }
  },
})
