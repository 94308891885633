
import { defineComponent, toRefs, PropType, watch } from 'vue'
import { CCustomersServices } from '@/types/component'
import { useTranslation } from '@/locales'
import { ApiCustomerService } from '@/types/api'
import { useRouter } from 'vue-router'
import { get as getCustomerSerice } from '@/api/admin/customers/services'

type Props = {
  customers: CCustomersServices[]
}

export default defineComponent({
  name: 'CustomerServicesList',

  props: {
    customers: {
      type: Object as PropType<CCustomersServices[]>,
      required: true,
    },
  },
  emits: ['open'],
  setup(props: Props, { emit }) {
    const { t, d, n } = useTranslation()
    const router = useRouter()

    const phyphone = ' - '

    const onUser = (customer: ApiCustomerService) => {
      emit('open', customer)
    }

    const onPaymentHistory = (customer: ApiCustomerService) => {
      router.push({
        path: 'billings',
        query: { customerId: customer.id },
      })
    }

    return { t, d, n, phyphone, onUser, onPaymentHistory }
  },
})
