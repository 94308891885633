<template>
  <tbody v-if="customers != null" class="bg-white divide-y divide-gray-200">
    <template v-for="(customer, index) in customers" :key="index">
      <tr v-for="(service, srvIndex) in customer.services" :key="srvIndex">
        <td
          v-if="srvIndex === 0"
          class="px-6 py-1 whitespace-nowrap"
          :rowspan="customer.services.length"
        >
          {{ customer.name }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.name }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.subscription }} {{ service.cancel }} {{ service.deleted }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.plan }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.startDate }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.expireDate }}
        </td>
        <td class="px-6 py-1 whitespace-nowrap">
          {{ service.contractEndDate }}
        </td>
        <td
          v-if="srvIndex === 0"
          class="px-6 py-1 whitespace-nowrap"
          :rowspan="customer.services.length"
        >
          {{ customer.paidDate }}
        </td>
        <td
          v-if="srvIndex === 0"
          class="px-6 py-1 whitespace-nowrap"
          :rowspan="customer.services.length"
        >
          <div class="mb-1">
            <button
              class="px-2 py-1 w-20 cursor-pointer text-xs font-medium text-white bg-blue-400 mr-2 rounded-xl"
              @click="onUser(customer)"
            >
              {{ t('User') }}
            </button>
          </div>
          <div>
            <button
              class="px-2 py-1 w-20 cursor-pointer text-xs font-medium text-white bg-red-400 rounded-xl"
              @click="onPaymentHistory(customer)"
            >
              {{ t('History') }}
            </button>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script lang="ts">
import { defineComponent, toRefs, PropType, watch } from 'vue'
import { CCustomersServices } from '@/types/component'
import { useTranslation } from '@/locales'
import { ApiCustomerService } from '@/types/api'
import { useRouter } from 'vue-router'
import { get as getCustomerSerice } from '@/api/admin/customers/services'

type Props = {
  customers: CCustomersServices[]
}

export default defineComponent({
  name: 'CustomerServicesList',

  props: {
    customers: {
      type: Object as PropType<CCustomersServices[]>,
      required: true,
    },
  },
  emits: ['open'],
  setup(props: Props, { emit }) {
    const { t, d, n } = useTranslation()
    const router = useRouter()

    const phyphone = ' - '

    const onUser = (customer: ApiCustomerService) => {
      emit('open', customer)
    }

    const onPaymentHistory = (customer: ApiCustomerService) => {
      router.push({
        path: 'billings',
        query: { customerId: customer.id },
      })
    }

    return { t, d, n, phyphone, onUser, onPaymentHistory }
  },
})
</script>
