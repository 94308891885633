<template>
  <table-frame>
    <template #header>
      <customer-services-tab />
    </template>
    <template #body>
      <customer-services-list :customers="customers" @open="open" />
    </template>
  </table-frame>
  <user-dialog :dialog="dialog" :customer="customer" @close="close" />
  <p v-if="customers.length === 0" class="mt-4 text-lg font-medium">
    {{ t('Now loading..') }}
  </p>
</template>

<script lang="ts">
import { defineComponent, toRefs, PropType, watch, ref } from 'vue'
import TableFrame from '@/components/Parts/Table/Frame.vue'
import { useObjectRefs } from '@/reactives'
import { useTranslation } from '@/locales'
import { CCustomersServices } from '@/types/component'
import CustomerServicesTab from './CustomerServicesTab.vue'
import CustomerServicesList from './CustomerServicesList.vue'
import UserDialog from './UserDialog.vue'
import { ApiCustomerService } from '@/types/api'

type Props = {
  customers: CCustomersServices[]
}

export default defineComponent({
  name: 'CustomerServicesTable',
  components: {
    CustomerServicesTab,
    CustomerServicesList,
    TableFrame,
    UserDialog,
  },
  props: {
    customers: {
      type: Object as PropType<CCustomersServices[]>,
      default: [],
      required: true,
    },
  },
  setup() {
    const customer = useObjectRefs<ApiCustomerService>()
    const { t } = useTranslation()
    const dialog = ref(false)

    const open = (cus: ApiCustomerService) => {
      customer.value = cus
      dialog.value = true
    }

    const close = () => {
      dialog.value = false
    }

    return { t, dialog, open, close, customer }
  },
})
</script>
