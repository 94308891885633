
import { defineComponent, ref, onBeforeMount, computed } from 'vue'
import { useArrayRefs } from '@/reactives'
import { ApiCustomerService, ApiUserResult } from '@/types/api'
import { useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { get as getCustomerSerice } from '@/api/admin/customers/services'
import { get as getCustomerUsers } from '@/api/admin/customer/users'
import CustomerServicesTable from '@/components/Customers/Services/index.vue'

export default defineComponent({
  components: { CustomerServicesTable },
  setup() {
    const customers = useArrayRefs<ApiCustomerService>()
    const users = useArrayRefs<ApiUserResult>()
    const dialog = ref(false)
    const { t } = useTranslation()
    const router = useRouter()

    onBeforeMount(() => {
      getCustomerSerice().then((result) => {
        customers.value = result
      })
    })

    const onClose = () => {
      dialog.value = false
    }

    const onUser = (customer: ApiCustomerService) => {
      getCustomerUsers(customer.id).then((result) => {
        users.value = result
      })

      dialog.value = true
    }

    const onPaymentHistory = (customer: ApiCustomerService) => {
      router.push({
        path: 'billings',
        query: { customerId: customer.id },
      })
    }

    const truncate = computed(() => {
      return (value: any) => {
        const length = 15
        const ommision = '...'
        if (value.length <= length) {
          return value
        }
        return value.substring(0, length) + ommision
      }
    })

    return {
      customers,
      users,
      dialog,
      truncate,
      onClose,
      onUser,
      onPaymentHistory,
      t,
    }
  },
})
